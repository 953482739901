import { Link } from "gatsby";
import * as React from "react";
import { Container, Row, Col, Modal, Breadcrumb } from "react-bootstrap";
import "./StaticBanner.scss";
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from "html-react-parser";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import { useLocation } from "@reach/router";
import ValuationFormPage from "../forms/valuation-form";
import { GetURL, getVideoId, removePlus } from "../utils";
import Slider from "react-slick";
import PlayVideo from "../Play/PlayVideo";
import moment from "moment";
import $ from "jquery"
import SocialList from "./social-list";

const videoRef = React.createRef(null);

const StaticBanner = (props) => {
    const [isPlaying, setPlaying] = React.useState(false);
    const [emailModal, setEmailModal] = React.useState(false);

    var { pathname } = useLocation();
    
    let processedImages = JSON.stringify({});
    if (props?.data?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = props?.data?.imagetransforms?.Banner_Image_Transforms;
    } 
    const location = useLocation();

    const playVideo = () => {
        var elem = videoRef.current;
        // const fullScreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled;
        // if(fullScreenAvailable) {
        //     if (elem.requestFullscreen) {
        //         elem.requestFullscreen();
        //     } else if (elem.webkitRequestFullscreen) { /* Safari */
        //         elem.webkitRequestFullscreen();
        //     } else if (elem.msRequestFullscreen) { /* IE11 */
        //         elem.msRequestFullscreen();
        //     }
        // } else {
        //     elem.classList.add("fullscreen-video")
        // }
        elem.classList.add("fullscreen-video")
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'formClick',
            'formType': 'form-video',
            'formId': 'local-living-video',
            'formName': "Local Living Video",
            'formLabel': window.location.href
        });
    }

    const closeVideo = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
        setPlaying(false)
    }


    function fullScreenHandler() {
        var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
        if(fullscreenElement == null) {
            setPlaying(false);
        }
    }

    React.useEffect(() => {
        document.addEventListener("fullscreenchange", fullScreenHandler, false);
        document.addEventListener("webkitfullscreenchange", fullScreenHandler, false);
        document.addEventListener("MSFullscreenChange", fullScreenHandler, false);
        document.addEventListener("mozfullscreenchange", fullScreenHandler, false);
    }, []);

    var officePhone = props?.siteConfig?.Office_Phone?.split(" ").join("")
    const url = typeof window !== "undefined" ? window.location.pathname : ""

    const calculatorSection = e => {
        $("html, body").animate({
            scrollTop:
              $("#mortgage-calculator-section").offset().top
        },100)
    }
    const pathArray = ['/mortgage', '/mortgage/'];
    let isPath = pathArray.includes(pathname);
    return (
        <React.Fragment>
            {isPath ? "" : <div className="static-detail-sec">
                <Container fluid className="">
                    <Breadcrumb className="mb-0 pb-0">
                        {(props.videos || props.areaGuides || props.news || location.pathname.includes("/guides/")) && <React.Fragment>
                            <Breadcrumb.Item>
                                <Link to="/">
                                    home
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {props.videos && <Link to="/dubai-property-videos/">
                                    videos
                                </Link>}
                                {props.areaGuides && <Link to="/living-in-dubai/">
                                    living in dubai
                                </Link>}
                                {props.news && <Link to="/knowledge-centre/latest-property-news/">
                                    news
                                </Link>}
                                {location.pathname.includes("/guides/") && 
                                    <Link to="/knowledge-centre/guides/">
                                        guides
                                    </Link>
                                }
                            </Breadcrumb.Item> 
                            {/* <Breadcrumb.Item></Breadcrumb.Item>  */}
                        </React.Fragment>}
                    </Breadcrumb>
                </Container>
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                {props.areaGuides && <h1 className="main-title">{props?.data?.Banner_Title}</h1>}
                                {(!props.areaGuides && !props.videos && !props.news) && 
                                    <React.Fragment>
                                        <h1 className="main-title">{props?.data?.SM_Title}</h1>
                                        <div className="news-info">
                                            <p>{props?.data?.Title}</p>
                                        </div>
                                    </React.Fragment>
                                }
                                {(props.videos || props.news) && <h1 className="main-title mt-4">{props?.data?.Title}</h1>}
                                {(props.areaGuides || props.videos) && 
                                    <div className="news-info">
                                        <p>{props?.data?.Description}</p>
                                    </div>
                                }
                                {(props.news || props.videos) && <div className="news-head d-xl-flex align-items-center justify-content-between static-banner-youtube">
                                    <div className="news-tab">
                                        <a>{props?.data?.Published_Date ? moment(props?.data?.Published_Date, "YYYY-MM-DD").format("MM/DD/YYYY") : ""}{props?.data?.Date ? moment(props?.data?.Date, "YYYY-MM-DD").format("MM/DD/YYYY") : ""}</a>
                                        <a className="text-uppercase active">{props?.data?.Category?.Category}</a>
                                    </div>
                                    <a href="https://www.youtube.com/user/EspaceRealEstate" target="_blank" className="subscribe-link d-none d-xl-block"><i className="icon-youtube-play"></i>subscribe to youtube</a>
                                </div>}
                                {props.areaGuides && 
                                    <div className="news-head mb-5 d-flex flex-row">
                                        <Link to="/sell-dubai-property/property-valuation/" className="subscribe-link">Property Valuation</Link>
                                        <Link to="/properties-for-sale/in-dubai/" className="subscribe-link ms-4">Find a property</Link>
                                    </div>
                                }
                                {(!props.areaGuides && props?.data?.Cta_1_Label) && 
                                    <div className="news-head mb-5 d-flex flex-row">
                                        {props?.data?.Cta_1_Label && <Link to={`/${GetURL(props?.data?.Cta_1_Link?.id)}`} className="subscribe-link">{props?.data?.Cta_1_Label}</Link>}
                                        {props?.data?.Cta_2_Label && <Link to={`/${GetURL(props?.data?.Cta_2_Link?.id)}`} className="subscribe-link ms-4">{props?.data?.Cta_2_Label}</Link>}
                                        {/* <a href="javascript:void(0)" className='subscribe-link ms-4' onClick={calculatorSection}>Mortgage Calculator</a> */}
                                    </div>
                                }
                               {props?.data?.Banner_Image && <div className="feature-img img-zoom">
                                    <ImageTransform
                                        imagesources={props?.data?.Banner_Image?.url}
                                        renderer="srcSet" imagename="articles.Banner_Image.static"
                                        attr={{ alt: props?.data?.Banner_Image?.alternativeText, class: '' }}
                                        imagetransformresult={processedImages}
                                        id={props?.data?.id}
                                    />
                                </div>}
                               {(props?.data?.Tile_Image && props.videos) && <div className="feature-img img-zoom">
                                    <ImageTransform
                                        imagesources={props?.data?.Tile_Image?.url}
                                        renderer="srcSet" imagename="videos.Tile_Image.banner"
                                        attr={{ alt: props?.data?.Title + " - Espace", class: '' }}
                                        imagetransformresult={processedImages}
                                        id={props?.data?.id}
                                    />
                                    <strong
                                        className="play-btn"
                                        onClick={(e) => {
                                            setPlaying(true);
                                            playVideo()
                                        }}
                                    >
                                        <i className="icon-play"></i>
                                    </strong>
                                </div>}
                                <div className="news-info d-xl-flex justify-content-between">
                                    {props.modules && <div className={`info ${((props?.data?.Speak_with_Team !== null && typeof props?.data?.Speak_with_Team !== "undefined") || props.areaGuides || props.news ) ? "order-xl-2": "w-100"}`}>
                                        {props?.modules.filter(c => c.Content && typeof c.Images !== "undefined").map((each, i) => 
                                            <React.Fragment key={i}>
                                                {each.Title && <h3 className="para-title">{each.Title}</h3>}
                                                <p>{HTMLReactParser(each.Content)}</p>
                                                <Slider slidesToScroll={1} slidesToShow={1} infinite={true} arrows={true} className="card-slider d-block h-auto">
                                                    {each.Images.map((img, k) => 
                                                        <div className="img-gallery" key={k}>
                                                            <div className="main-img img-zoom">
                                                                <img src={img.url} alt="image" className="info-img-list"/>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Slider>
                                            </React.Fragment>
                                        )}
                                    </div>}
                                    {!props.videos && <div className="social-details order-xl-1">
                                        <div className="wrp-info d-sm-flex justify-content-start d-xl-block">
                                            {/* {((props?.data?.Speak_with_Team != null && typeof props?.data?.Speak_with_Team != "undefined") && !props.areaGuides) && <div className="contact-agent d-flex justify-content-between">
                                                <div className="contact-agent-photo">
                                                    <img src={props?.data?.Speak_with_Team.Tile_Image?.url} alt="" />
                                                </div>
                                                <div className="contact-agent-info">
                                                    <span className="contact-agent-name d-block">
                                                        {props?.data?.Speak_with_Team.Name}
                                                    </span>
                                                    <span className="auth-txt d-block">{props?.data?.Speak_with_Team?.Designation}</span>
                                                    {props?.data?.Speak_with_Team?.Phone && <Link
                                                        href={`tel: ${props?.data?.Speak_with_Team?.Phone}`}
                                                        className="contact-agent-number"
                                                    >
                                                        {props?.data?.Speak_with_Team?.Phone}
                                                    </Link>}
                                                </div>
                                            </div>} */}
                                            {/* {props.areaGuides &&  */}
                                                <div className="contact-agent d-flex flex-column">
                                                    <div className="contact-agent d-flex justify-content-between w-100">
                                                        <div className="contact-agent-photo">
                                                            <img src={props?.siteConfig?.Office_Image?.url} alt={props?.siteConfig?.Office_Image?.alternativeText} />
                                                        </div>
                                                        <div className="contact-agent-info">
                                                            <span className="contact-agent-name d-block">
                                                                Contact Us
                                                            </span>
                                                            {props?.siteConfig?.Office_Phone && <Link
                                                                href={`tel: ${officePhone}`}
                                                                className="contact-agent-number"
                                                            >
                                                                {props?.siteConfig?.Office_Phone}
                                                            </Link>}
                                                        </div>
                                                    </div>
                                                    <div className="social-details w-100">
                                                        <a 
                                                            href={`https://api.whatsapp.com/send?phone=${removePlus(props?.siteConfig?.Office_Whatsapp)}`} 
                                                            target="_blank" 
                                                            className="btn subscribe-btn speak-btn">Whatsapp us
                                                        </a>
                                                    </div>
                                                </div>
                                            {/* } */}
                                            <div className="social-list">
                                                <SocialList />                                                
                                            </div>
                                        </div>
                                        <p>Do you need a property valuation?</p>
                                        <a href="javascript:;" onClick={() => setEmailModal(true)} className="btn subscribe-btn speak-btn">speak with us</a>
                                    </div>}
                                </div>
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </div>}
            {/* <NewsSuggestions /> */}
            <Modal size="lg" show={emailModal} onHide={() => setEmailModal(false)} centered className="contact-form-sec">
                <div className="contact-form subsribe-form">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Contact Espace</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ValuationFormPage/>
                    </Modal.Body>
                </div>
            </Modal>
            <div ref={videoRef}>
                {props.videos && <PlayVideo
                    isOpen={isPlaying}
                    isCloseFunction={setPlaying}
                    videoId={getVideoId(props?.data?.Embed_Video_Url)}
                    isAutoPlay={1}
                />}
                <strong className="play-btn close-icon" 
                    onClick={() => { 
                        setPlaying(false); videoRef.current.classList.remove("fullscreen-video")
                    }}>
                    <i className="icon-close"/>
                </strong>
            </div>
        </React.Fragment>
    );
};
export default StaticBanner;