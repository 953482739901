import * as React from "react";
import { useLocation } from '@reach/router';
import {FacebookShareButton,LinkedinShareButton, TwitterShareButton } from 'react-share';


const SocialList = props => {
    const[showLink, setShowLink] = React.useState(false);

    const trackerShare = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Share - social icons',
            'formType': event + ' - ' + props.url,
            'formId': 'Share',
            'formName': 'Share',
            'formLabel': 'Share'
        });
    }
    const location = useLocation();
    
    return (
        <div className="social-list">
        <h6 className="social-title">Share:</h6>
            <div className="social-links position-relative">
                <a href="javascript:;">
                    <TwitterShareButton url={location.href} onClick={() => trackerShare("TwitterShareButton")}>
                        <i className="icon-twitter-dark"></i>
                    </TwitterShareButton>
                </a>
                <a href="javascript:;">
                    <FacebookShareButton url={location.href} onClick={() => trackerShare("FacebookShareButton")}>
                        <i className="icon-facebook-dark"></i>
                    </FacebookShareButton>
                </a>
                <a href="javascript:;">
                    <LinkedinShareButton url={location.href} onClick={() => trackerShare("LinkedinShareButton")}>
                        <i className="icon-linkedin-dark"></i>
                    </LinkedinShareButton>
                </a>
                <a href="javascript:;" 
                    title="Copy Link" 
                    onClick={() => {
                        navigator.clipboard.writeText(location.href);
                        setShowLink(true);
                        setTimeout(() => {
                            setShowLink(false)
                        }, 1000);
                    }} 
                    className="position-relative"
                >
                    <i className="icon-url-dark"></i>
                </a>
                {showLink && <div className="alert alert-light link-alert">Link copied</div>}
            </div>
        </div>
    );
};
export default SocialList;
