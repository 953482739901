import { graphql } from "gatsby";
import GoogleMapReact from "google-map-react";
import React from "react";
import ContactForm from "../components/AllModules/ContactForm/ContactForm";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
// import LocRatingMap from "../components/Map/loc-rating-map";
import { MapMarkerInfoBox } from "../components/Map/map-marker";
import StaticBanner from "../components/StaticBanner/StaticBanner";
import markerImg from "../components/Map/images/map_marker.png";
import FeaturedPropertiesByArea from "../components/FeaturedProperties/FeaturedPropertiesByArea";
import SEO from "../components/seo";

const Marker = () => {
    return (
        <img src={markerImg}/>
    )
}

const NewsTemplate = (props) => {
    const { areaGuide, siteConfig } = props.data.glstrapi;

    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = areaGuide?.Latitude
    mapItem['lng']  = areaGuide?.Longitude
    const mapMarkerImageShowFlag = 0;

    let mapItemHtml = MapMarkerInfoBox(areaGuide?.Title, mapMarkerImageShowFlag);
    mapItem['html'] = mapItemHtml;
    mapItems.push(mapItem);

    return (
        <div>
            <Header/>
            <SEO
                title={areaGuide?.Title + " Community Guide"}
                description={`Know more about ${areaGuide?.Title} here. Contact our real estate professionals to get assistance in buying, selling or leasing properties in ${areaGuide?.Title}.`}
                location={props.location}
            />
            <StaticBanner
                areaGuides={true}
                data={areaGuide}
                siteConfig={siteConfig}
                modules={areaGuide?.Content_With_Images}
            />
            <div className="area-guide-map">
                {/* <LocRatingMap
                    data={mapItems}
                /> */}
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: "AIzaSyDzDhH1zFVAFyliVKD1m\_PoNShDy77wau8"
                    }}
                    // bootstrapURLKeys={{ key: "" }}
                    defaultCenter={{
                        lat: 25.13797501888234,
                        lng: 55.12747016889561
                    }}
                    defaultZoom={11}
                >
                    <Marker
                        lat={areaGuide?.Latitude}
                        lng={areaGuide?.Longitude}
                    />
                </GoogleMapReact>
            </div>
            <FeaturedPropertiesByArea
                data={{
                    Title: "Featured Properties"
                }}
                area={areaGuide?.Area}
            />
            <ContactForm/>
            <Footer
                popularSearch="Popular_Static"
            />
        </div>
    )
}

export default NewsTemplate;

export const pageQuery = graphql`
    query areaGuideQuery($id: ID!) {
        glstrapi {
            areaGuide(id: $id, publicationState: LIVE) {
                id
                Title
                Meta_Title
                Area
                Meta_Description
                Longitude
                Latitude
                Embed_Video_Url
                Description
                Banner_Title
                Banner_Image {
                    url
                    alternativeText
                }
                Content_With_Images {
                    id
                    Title
                    Content
                    Images {
                        url
                        alternativeText
                    }
                }
                Url
                Sort
            }
            siteConfig {
                Office_Email
                Office_Phone
                Office_Whatsapp
                Office_Image {
                    url
                    alternativeText
                }
            }
        }
    }
`
