import { Link } from "gatsby"
import React from "react"
import Slider from "react-slick"
import { Container, Tab, Nav } from "react-bootstrap"
// Images
import "./FeaturedProperty.scss"
import ScrollAnimation from "react-animate-on-scroll"
import PropertyCard from "../Home/PropertyCard/PropertyCard"
import $ from "jquery"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import classNames from "classnames"

const propertyListQuery = gql`
  query FeaturedProperties($Prop_Area: String!) {
    sales: properties(
      where: {
        display_address_contains: $Prop_Area
        search_type: "sales"
        publish: true
      }
      limit: 10
    ) {
      _id
      id
      display_address
      images
      price
      price_qualifier
      search_type
      slug
      floorarea_max
      floorarea_min
      floorarea_type
      floor_area
      building
      bedroom
      bathroom
      address
      imagetransforms
      max_price
      status
      title
      video_link
      virtual_tour
    }
    lettings: properties(
      where: {
        display_address_contains: $Prop_Area
        search_type: "lettings"
        publish: true
      }
      limit: 10
    ) {
      _id
      id
      display_address
      images
      price
      price_qualifier
      search_type
      slug
      floorarea_max
      floorarea_min
      floorarea_type
      floor_area
      building
      bedroom
      bathroom
      address
      imagetransforms
      max_price
      status
      title
      video_link
      virtual_tour
    }
  }
`

const FeaturedPropertiesByArea = props => {
  const [type, setType] = React.useState("sales")
  const settings1 = {
    dots: false,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    mobileFirst: true,
    centerMode: false,
    variableWidth: true,
    centerPadding: "18px",
  }

  const { data, loading, error } = useQuery(propertyListQuery, {
    variables: {
      Prop_Area: props.area,
    },
  })
  React.useEffect(() => {
    $("a[href='#']").removeAttr("href")
  }, [])
  return (
    <div className="property-tabsec similar-properties-details">
      <Container>
        <ScrollAnimation animateIn="fadeInUp" animateOnce>
          <div className="similar-head d-md-flex justify-content-between align-items-center">
            <h2 className="main-title">
              {props?.data?.Title} in {props?.area}
            </h2>
            {props.data && props.data.Cta_Link && (
              <Link
                to={`/${props.data.Cta_Link.URL}/`}
                className="view-more text-uppercase d-none d-md-inline-block"
              >
                view more
              </Link>
            )}
          </div>
        </ScrollAnimation>
        <div className="navtab-list">
          <Tab.Container id="left-tabs-example" defaultActiveKey="sales">
            <Nav variant="pills" className="mb-3">
              <Nav.Item className="ms-0 items1">
                <Nav.Link
                  eventKey="sales"
                  className={classNames("ps-0 pe-3", {
                    active: type === "sales",
                  })}
                  onClick={() => setType("sales")}
                >
                  PROPERTY FOR SALE IN {props?.area?.toUpperCase()}
                </Nav.Link>
              </Nav.Item>
              {data?.lettings?.length > 0 && (
                <Nav.Item className="ms-0 items2">
                  <Nav.Link
                    eventKey="lettings"
                    className={classNames("ps-0 pe-3", {
                      active: type === "lettings",
                    })}
                    onClick={() => setType("lettings")}
                  >
                    PROPERTY FOR RENT IN {props?.area?.toUpperCase()}
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="sales">
                <Slider className="banner-imgslider d-block" {...settings1}>
                  {data?.sales?.map((property, i) => (
                    <div className="img-item" key={i}>
                      <PropertyCard
                        cardImg={
                          property.images.filter(c => c.url != null)[0].url
                        }
                        imagename="property.images.detail"
                        id={property._id}
                        attr={{ class: "" }}
                        property={property}
                        cardTitle={property.title}
                        searchType={property.search_type}
                        cardPrice={`AED ${property.price.toLocaleString()}`}
                        cardDesc={property.display_address}
                        totalBed={`${property.bedroom} Bedrooms`}
                        totalSize={`${property.floorarea_min} ${property.floorarea_type}.`}
                      />
                    </div>
                  ))}
                </Slider>
              </Tab.Pane>
              <Tab.Pane eventKey="lettings">
                <Slider className="banner-imgslider d-block" {...settings1}>
                  {data?.lettings?.map((property, i) => (
                    <div className="img-item" key={i}>
                      <PropertyCard
                        cardImg={
                          property.images.filter(c => c.url != null)[0].url
                        }
                        imagename="property.images.detail"
                        id={property._id}
                        attr={{ class: "" }}
                        property={property}
                        cardTitle={property.title}
                        searchType={property.search_type}
                        cardPrice={`AED ${property.price.toLocaleString()}`}
                        cardDesc={property.display_address}
                        totalBed={`${property.bedroom} Bedrooms`}
                        totalSize={`${property.floorarea_min} ${property.floorarea_type}.`}
                      />
                    </div>
                  ))}
                </Slider>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </div>
  )
}

export default FeaturedPropertiesByArea
